import Vuex from 'vuex'
import Vue from 'vue'
import http from '@/http'


Vue.use(Vuex)

const state = {
    token: localStorage.getItem('token'),
    usuario: {}
}
const mutations = {
    DEFINIR_USUARIO_LOGADO(state, {
        token,
        usuario
    }) {
        state.token = token
        state.usuario = usuario
    },
    DESLOGAR_USUARIO(state) {
        state.token = null
        state.usuario = {}
    }
}
const actions = {
    login({
        commit
    }, usuario) {
        return new Promise((resolve, reject) => {
            return http.post('login', usuario)
                .then(({
                    data
                }) => {
                    localStorage.setItem('token', data.access_token)
                    commit('DEFINIR_USUARIO_LOGADO', {
                        token: data.access_token,
                        usuario: data.user
                    })
                    resolve(data)
                })
                .catch(error => reject(error.response.data.detail))
        })
    },
    cadastro({
        commit
    }, usuario) {
        commit
        return new Promise((resolve, reject) => {
            http.post('register', usuario)
                .then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error.response.data.detail);
                });
        });
    }
};
export default new Vuex.Store({
    state,
    mutations,
    actions
})