import Login from '@/components/login/login-cliente.vue';
import BankConverter from '@/components/converter/bank-converter.vue';
import NovoUsuario from '@/components/cadastro/cadastro-cliente.vue';
import Categorias from '@/components/categorias/listas-categorias.vue';
import Historico from '@/components/historico/historico-cliente.vue';
import SejaPremium from '@/components/premium/seja-premium.vue';


import "bootstrap-icons/font/bootstrap-icons.css";

export const routes = [{
  path: '/cadastro',
  name: 'novo.usuario',
  component: NovoUsuario,
  menu: true,
  meta: {
    title: 'Cadastro',
    public: true
  }
}, {
  path: '/login',
  name: 'login',
  component: Login,
  menu: true,
  meta: {
    title: 'Login',
    public: true
  }

}, {
  path: '/conversor',
  name: 'bank.converter',
  component: BankConverter,
  menu: true,
  meta: {
    title: 'Converter',
    public: false
  }
},
{
  path: '/historico',
  name: 'historico',
  component: Historico,
  menu: true,
  meta: {
    title: 'Histórico',
    public: false
  }

},

{
  path: '/categorias',
  name: 'categorias',
  component: Categorias,
  menu: true,
  meta: {
    title: 'Categorias',
    public: false
  }

},
{
  path: '/premium',
  name: 'seja-premium',
  component: SejaPremium,
  menu: true,
  meta: {
    title: 'Seja-Premium',
    public: true
  }
},
{
  path: '*',
  component: Login,
  menu: false,
  history: false
}
];