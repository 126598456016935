<template>
  <nav class="navbar navbar-expand-lg navbar-dark" :style="{ backgroundColor: '#d3c791' }">
    <div class="container-fluid h4 mb-4 mt-4">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon fw-bold"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <a class="navbar-brand" href=" ">
            <img src="@/assets/img/icon.png" width="30" height="30" class="d-inline-block align-top" alt="" /></a>
          <li class="nav-item" v-for="rota in rotas.filter((r) => r.meta.public != usuarioLogado)" :key="rota.id">
            <router-link class="nav-link" :to="rota.path ? rota.path : '/login'">
              {{ rota.meta.title }}
            </router-link>
          </li>
          <li class="nav-item" v-if="usuarioLogado">
            <a class="nav-link" @click.prevent="efetuarLogout" href="#">
              Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    rotas: {
      type: Array,
      required: true,
    },
  },
  methods: {
    efetuarLogout() {
      localStorage.removeItem("token");
      this.$store.commit("DESLOGAR_USUARIO");
      this.$router.push({ name: "login" });
    },
  },
  computed: {
    usuarioLogado() {
      return Boolean(this.$store.state.token);
    },
  },
};
</script>

<style></style>
