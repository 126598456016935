import axios from "axios";

const http = axios.create({
    baseURL: 'https://conversor-api.agilehero.com.br/',
    headers: {
        'Accept': 'application/json',
    },
    timeout: 120000
})

http.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}, function (erro) {
    return Promise.reject(erro)
})

http.interceptors.response.use(function (response) {
    return response
}, function (erro) {
    // quando a tela é carregada com um token invalido, o usuario vai ser redirecionado para a tela de login
    // isso é um mecanismo de segurança necessário pois o token expira depois de algum tempo
    if (erro.response.status == 401 && window.location.pathname != '/login') {
        localStorage.removeItem("token");
        window.location.pathname = '/login'
    }
    return Promise.reject(erro)
})

export default http