<template>
  <div class="container">
    <h1 class="title fw-bold text-center pt-4 pb-4" id="titulo-2">
      Histórico de Conversões
    </h1>
    <p>
      Nesta tela, você pode baixar os arquivos convertidos com facilidade.</p>
    <p>Caso algum arquivo não funcione como esperado, não se preocupe! <a
        href="https://api.whatsapp.com/send?phone=5511976079408&text=Ola,+preciso+de+ajuda+no+conversor"
        target="_blank">Entre em contato com o suporte</a>
      - (11) 97607-9408, e nossa equipe estará pronta para ajudar e ajustar o arquivo para você.
    </p>
    <table class="table table-hover table-bordered text-center">
      <thead class="thead-dark">
        <tr class="h3 text-light bg-primary">
          <th scope="col">Data</th>
          <th scope="col">Tipo</th>
          <th scope="col">Download</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="historico in historicos" :key="historico.uuid">
          <td class="h6 pt-4">{{ historico.created_at | formatarData }}</td>
          <td class="h6 pt-4">{{ historico.type }}</td>
          <td class="h6 pt-4">
            <div>
              <div class="d-grid fw-bold text-light bg-primary">
                <a v-if="historico.csv_link" :href="historico.csv_link"
                  class="btn btn-outline-light border-dark">Download</a> <span v-else
                  class="btn  border-dark text-light bg-danger">Erro ao converter, entre em contato com o suporte</span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import http from "@/http";

export default {
  data() {
    return {
      historicos: [],
    };
  },
  methods: {
    listarHistorico() {
      http
        .get("conversions")
        .then((response) => {
          this.historicos = response.data.data;
        })
        .catch((erro) => console.log(erro));
    },
  },
  mounted() {
    this.listarHistorico();
  },
};
</script>

<style></style>
