<template>
  <div class="container">
    <div class="row">
      <div class="col-sm"></div>
      <div class="col-md-6 pt-5 mb-5 col-sm-9" id="form-container">
        <form class="form-control" @submit.prevent="submit" id="formCadastro">
          <h2 class="fs-2 text-light text-center fw-bold mb-3" id="cabeçario-form">
            Cadastro
          </h2>
          <div>
            <label class="fs-5 pt-2 mb-2" for="exampleInputname">Nome</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="bi bi-person"></i></span>
              </div>
              <input type="text" v-model="usuario.name" class="form-control" style="border-left: none"
                placeholder="Digite seu nome completo." name="name" required />
            </div>
          </div>

          <div>
            <label class="fs-5 pt-2 mb-2" for="exampleInputEmail">Email</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="bi bi-envelope"></i></span>
              </div>
              <input type="email" v-model="usuario.email" class="form-control" style="border-left: none"
                id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Escreva o email aqui." required
                name="email" />
            </div>
            <small id="emailHelp" class="form-text text-muted">
              Nunca compartilharemos seu e-mail com mais ninguém.
            </small>
          </div>

          <div>
            <label class="fs-5 pt-4 mb-2" for="Inputtelephone">Digite o seu Número</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="bi bi-telephone"></i></span>
              </div>
              <input type="number" v-model="usuario.phone" class="form-control" style="border-left: none" id="inputFone"
                placeholder="Seu número de whatsapp com DDD." pattern="\([0-9]{2}\)[0-9]{4,6}-[0-9]{3,4}$" required
                name="phone" />
            </div>
          </div>

          <div>
            <label class="fs-5 pt-2 mb-2" for="InputPassword">Senha</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="bi bi-lock"></i></span>
              </div>
              <input type="password" v-model="usuario.password" class="form-control" style="border-left: none"
                name="password" placeholder="Digite uma senha." required />
            </div>
          </div>
          <div class="fs-5 pt-4 mb-4">
            <div class="d-grid gap-2 fw-bold fs-6 text-light bg-dark">
              <button id="btn-converter" class="btn btn-outline-light border-dark fs-4" type="submit"
                :disabled="btnDisabled">
                <span v-if="btnDisabled" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span>
                <span v-else>Cadastrar</span>
              </button>
            </div>
          </div>
          <br />
        </form>
      </div>
      <div class="col-sm"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      usuario: {
        nome: "",
        senha: "",
        email: "",
      },
      btnDisabled: false,
    };
  },
  created() {
    const usuarioLogado = localStorage.getItem('token');
    if (usuarioLogado) {
      this.$router.push({ name: 'bank.converter' });
    }
  },
  methods: {
    submit() {
      this.btnDisabled = true;
      this.$store
        .dispatch("cadastro", this.usuario)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Sucesso!",
            text: "Seu cadastro foi realizado com sucesso",
            timer: 4000,
          });
          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ops!",
            text: error,
            timer: 4000,
          });
        })
        .finally(() => {
          this.btnDisabled = false;
        });
    },
  },
};
</script>
<style>
.cadastro {
  padding: 10%;
}

.input-group-text {
  background-color: #fff;
  border: 1px solid #ced4da;
}

.bi {
  font-size: 1.1rem;
}
</style>
