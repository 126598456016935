import VueSweetalert2 from 'vue-sweetalert2';
import Vue from 'vue';
import App from './App.vue';
import VueResource from 'vue-resource';
import VueRouter from 'vue-router';
import store from './store';
import {
  routes
} from "./routes";
import 'sweetalert2/dist/sweetalert2.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";

Vue.use(VueSweetalert2);
Vue.config.productionTip = false

Vue.use(VueRouter);

Vue.filter('formatarData', function (value) {
  if (!value) return '';
  return new Date(value).toLocaleString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
});

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach((routeTo, routeFrom, next) => {
  document.title = `${process.env.VUE_APP_TITLE} - ${routeTo.meta.title}`
  if (!routeTo.meta.public && !store.state.token) {
    return next({ path: 'login' });
  }
  next();
})


export default router


Vue.use(VueResource);

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})