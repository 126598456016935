<template>
  <div class="corpo">
    <meu-menu :rotas="routes" />

    <Transition name="pagina">
      <router-view></router-view>
    </Transition>
  </div>
</template>

<script>
import { routes } from "./routes";
import MainMenu from "./components/menu/main-menu.vue";

export default {
  components: {
    "meu-menu": MainMenu,
  },
  data() {
    return {
      routes: routes.filter((route) => route.menu),
    };
  },
};
</script>

<style>
.corpo {
  font-family: Helvetica, sans-serif;
  width: 100%;
}

nav a:hover {
  color: #0095fb !important;
}
.btn[disabled] {
  background-color: #646464;
  color: #fffdfd;
  cursor: not-allowed;
}
#titulo-2 {
  color: rgb(211, 199, 145);
  text-shadow: 3px 3px 0 #3d3d3d, -1px -1px 0 #6f6f6f, 1px -1px 0 #929292,
    -1px 1px 0 #2c2c2c, 1px 1px 0 #323232;
}
</style>
